import {AccessibilityTimeframe, SiteAccessibility} from "@variocube/app-ui";
import {createElement, Fragment, useEffect, useState} from "react";
import {getStorageItem} from "../../data/cubes";
import {useLocalization} from "../../i18n";
import {tenantUserStore} from "../../store/TenantUserStore";
import {DateTime} from "luxon";

export interface CubeAccessibilityComponentProps {
	cubeId: string;
	onAccessibilityLoaded?: (siteAccessibility: SiteAccessibility) => void;
}

export function CubeAccessibilityComponent(props: CubeAccessibilityComponentProps) {
	const {t} = useLocalization();
	const tenantId = tenantUserStore.getTenantId();
	const [accessibility, setAccessibility] = useState<SiteAccessibility>();
	useEffect(() => {
		getStorageItem(tenantId, props.cubeId, "accessibility")
			.then((item) => {
				if (item.value) {
					setAccessibility(JSON.parse(item.value));
				}
			}).finally(() => {});
	}, [props.cubeId]);

	useEffect(() => {
		if (props.onAccessibilityLoaded && accessibility) {
			props.onAccessibilityLoaded(accessibility);
		}
	}, [accessibility]);

	function getTimeframe(wda: AccessibilityTimeframe, access: SiteAccessibility) {
		const from = wda.accessibleFrom ?? access.accessibleFrom;
		const until = wda.accessibleUntil ?? access.accessibleUntil;
		return [from, until]
			.filter(v => v !== undefined)
			.map(v => {
				const time = (v as string).split('.');
				return DateTime.fromFormat(time[0], 'HH:mm:ss').toFormat('HH:mm')
			})
			.join(' - ');
	}

	if (accessibility) {
		return (
			<Fragment>
				{accessibility.alwaysAccessible && <div>24/7</div>}
				{!accessibility.alwaysAccessible && accessibility.weekdayAccessibleHours
					&& accessibility.weekdayAccessibleHours.map((wda) => (
						<div>
							{t(`weekdays.${wda.weekday}` as any) + ": " + (wda.accessibleFrom
								? wda.accessibleFrom
								: accessibility.accessibleFrom)
								+ " - " + (wda.accessibleUntil
									? wda.accessibleUntil
									: accessibility.accessibleUntil)}
						</div>
					))}
			</Fragment>
		);
	}
	return <Fragment />;
}
