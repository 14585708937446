import {createQueryString, Page, Paging} from "@variocube/app-ui";
import {Api} from "../Api";
import {Box, Cube, ItemStoreRequest, SetBoxStateRequest} from "./types";

export function listCubes(tenantId: string, paging?: Paging) {
	return Api.GET<Page<Cube>>(`tenants/${tenantId}/cubes` + (paging ? paging.toQueryString() : `?${createQueryString({ unpaged: true })}`));
}

export function getCube(tenantId: string, cubeId: string) {
	if (cubeId) {
	    return Api.GET<Cube>(`tenants/${tenantId}/cubes/${cubeId}`);
	} else {
		throw new Error('CubeId not provided!');
	}
	
}

export function listCubesBoxes(tenantId: string, cubeId: string) {
	if (tenantId && cubeId) {
		return Api.GET<Box[]>(`tenants/${tenantId}/cubes/${cubeId}/boxes`);
	} else {
		throw new Error('TenantId or CubeId not provided!');
	}
}

export function getBox(tenantId: string, cubeId: string, boxNumber: string) {
	return Api.GET<Box>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}`);
}

export function openBox(tenantId: string, cubeId: string, boxNumber: string) {
	return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/open`, {});
}

export function openAllBoxes(tenantId: string, cubeId: string) {
	return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/open-all-boxes`, {});
}

export function setBoxState(tenantId: string, cubeId: string, boxNumber: string, request: SetBoxStateRequest) {
	return Api.PUT<void>(`tenants/${tenantId}/cubes/${cubeId}/boxes/${boxNumber}/set-state`, request);
}

export function listItems(tenantId: string, cubeId: string) {
	return Api.GET<void>(`tenants/${tenantId}/cubes/${cubeId}/items`);
}

// Hint: use boxType and not boxNumber. Storing in dedicated box might be rejected by the cube controller.
export function storeItem(
	tenantId: string,
	cubeId: string,
	storeItemRequest: ItemStoreRequest,
) {
	return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/items`, storeItemRequest);
}

// @DeleteMapping("tenants/{tenantId}/cubes/{cubeId}/items/{itemUuid}")
export function removeItem(tenantId: string, cubeId: string, itemUuid: string) {
	return Api.DELETE<void>(`tenants/${tenantId}/cubes/${cubeId}/items/${itemUuid}`);
}

export type CubeStorageItem = {
	cubeId: string;
	key: string;
	value: string;
};

// get configuration value
export function getStorageItem(tenantId: string, cubeId: string, key: string) {
	return Api.GET<CubeStorageItem>(`tenants/${tenantId}/cubes/${cubeId}/storageitems/${key}`);
}

// set configuration value
export function writeStorageItem(tenantId: string, cubeId: string, storeItemRequest: CubeStorageItem) {
	return Api.POST<void>(`tenants/${tenantId}/cubes/${cubeId}/storageitems/${storeItemRequest.key}`, storeItemRequest);
}
