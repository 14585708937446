import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	LinearProgress,
	MenuItem,
	Paper,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, ColumnType, ContentTable, Page, PagingSettings} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {ApiError} from "../../Api";
import {AddFab} from "../../components/AddFab";
import {PushButtonWithMenu} from "../../components/buttons/PushButtonWithMenu";
import {CubeDescriptionDisplay} from "../../components/CubeDescriptionDisplay";
import {ErrorDialog} from "../../components/ErrorDialog";
import {TableSearchInput} from "../../components/TableSearchInput";
import {itemRegenerateAllItemCodes, listItems, RentalFilterData} from "../../data/items";
import {itemsPaging} from "../../data/pagings";
import {Item} from "../../data/types";
import {useLocalization} from "../../i18n";
import {RentalAppContainer} from "../../RentalAppContainer";
import {tenantUserStore} from "../../store/TenantUserStore";
import {convertInstantString, convertPlainDateTime} from "../../tools";
import {RentalFilter} from "../rentals/RentalFilter";
import {ItemStateDisplay} from "./ItemStateDisplay";
import {useNavigate} from "react-router-dom";

export const ItemList = () => {
	const {t} = useLocalization();
	const navigate = useNavigate();

	useEffect(() => {
		setColumns({
			"name": {show: true, name: t("common.name")},
			"foreignId": {show: true, name: t("items.foreignId")},
			"category": {show: true, name: t("categories.singular")},
			"model": {show: true, name: t("models.singular")},
			"stored.cubeId": {show: true, name: `${t("cubes.singular")} / ${t("boxes.singular")}`},
			"adminCode": {show: false, name: t("items.codes.adminCode")},
			"openCode": {show: false, name: t("items.codes.openCode")},
			"disabled": {show: true, name: t("common.state")},
		});
	}, [t]);

	const [page, setPage] = useState<Page<Item>>();
	const [inProgress, setInProgress] = useState<boolean>(true);
	const [regenerate, setRegenerate] = useState<boolean>(false);
	const [columns, setColumns] = useState<ColumnType>({});
	const [error, setError] = useState<ApiError>();
	const [needle, setNeedle] = useState<string>("");
	const canImport = tenantUserStore.admin;
	const [filterDialog, setFilterDialog] = useState<boolean>(false);
	const [filter, setFilter] = useState<RentalFilterData>({});

	const loadPage = useCallback(async (needleParam: string | undefined, filter: RentalFilterData) => {
		needleParam = needleParam || needle;
		setInProgress(true);
		try {
			const page = await listItems(tenantUserStore.getTenantId(), itemsPaging, needleParam);
			setPage(page);
		} catch (error) {
			itemsPaging.resetSettings();
			setError(error as any);
		}
		setInProgress(false);
	}, [setInProgress, setPage, setError]);

	useEffect(() => {
		loadPage(needle, filter).then();
	}, []);

	const showCell = (column: keyof typeof columns) => columns[column] && columns[column].show;

	const handleSearch = (needle?: string) => {
		itemsPaging.updateSettings({
			...itemsPaging.getSettings(),
			pageNumber: 0,
		});
		setNeedle(needle || "");
		loadPage(needle, filter).then();
	};

	const handlePageChange = (settings: PagingSettings<any>) => {
		itemsPaging.updateSettings({
			...itemsPaging.getSettings(),
			...settings,
		});
		loadPage(needle, filter).then();
	};

	const handleRegenerateAllItemCodes = async () => {
		setInProgress(true);
		try {
			await itemRegenerateAllItemCodes(tenantUserStore.getTenantId());
			await loadPage(needle, filter);
			setRegenerate(false);
		} finally {
			setInProgress(false);
		}
	};

	const handleFilter = (newFilter: RentalFilterData) => {
		setFilter({...newFilter});
		const settings = itemsPaging.getSettings();
		settings.filters = {};
		if (newFilter.needle) {
			settings.filters["needle"] = newFilter.needle;
		}
		if (newFilter.rentalState) {
			if ((newFilter.rentalState as any) === "ALL") {
				delete settings.filters["rentalState"];
			} else {
				settings.filters["rentalState"] = newFilter.rentalState;
			}
		}
		if (newFilter.categoryUuid) {
			settings.filters["categoryUuid"] = newFilter.categoryUuid;
		} else {
			delete settings.filters["categoryUuid"];
		}
		if (newFilter.modelUuid) {
			settings.filters["modelUuid"] = newFilter.modelUuid;
		} else {
			delete settings.filters["modelUuid"];
		}
		settings.filters["from"] = convertPlainDateTime(newFilter.from);
		settings.filters["until"] = convertPlainDateTime(newFilter.until);

		if (newFilter.from && newFilter.until) {
			settings.filters["rentedFrom"] = convertPlainDateTime(newFilter.from);
			settings.filters["rentedUntil"] = convertPlainDateTime(newFilter.until);
		} else if (newFilter.availableFrom && newFilter.availableUntil) {
			settings.filters["availableFrom"] = convertPlainDateTime(newFilter.availableFrom);
			settings.filters["availableUntil"] = convertPlainDateTime(newFilter.availableUntil);
		}

		itemsPaging.updateSettings({
			...settings,
		});

		onPagingChange(settings);
		setFilterDialog(false);
	};

	useEffect(() => {
		const filters = itemsPaging.getSettings().filters;
		if (filters) {
			filter.needle = filters["needle"];
			filter.rentalState = filters["rentalState"];
			filter.from = convertInstantString(filters["from"]);
			filter.until = convertInstantString(filters["until"]);
			setFilter(filter);
		}
	}, []);

	const handleResetFilter = () => {
		setFilter({});
		itemsPaging.updateSettings({
			...itemsPaging.getSettings(),
			filters: {},
		});
		onPagingChange(itemsPaging.getSettings());
		setFilterDialog(false);
	};

	const filterIsFiltering = () => {
		return Boolean(
			(filter.needle && filter.needle.length > 0)
				|| filter.rentalState
				|| filter.categoryUuid
				|| filter.modelUuid
				|| filter.from
				|| filter.until
				|| filter.availableFrom
				|| filter.availableUntil,
		);
	};

	const handleResetSingleFilter = (filterProp: string) => {
		const settings = itemsPaging.getSettings();
		if (settings.filters) {
			switch (filterProp) {
				case "needle":
					filter.needle = undefined;
					settings.filters["needle"] = undefined;
					break;
				case "rentalState":
					filter.rentalState = undefined;
					settings.filters["rentalState"] = undefined;
					break;
				case "from":
					filter.from = null;
					filter.availableFrom = null;
					settings.filters["from"] = null;
					settings.filters["availableFrom"] = null;
					settings.filters["rentedFrom"] = null;
					break;
				case "until":
					filter.until = null;
					filter.availableUntil = null;
					settings.filters["until"] = null;
					settings.filters["availableUntil"] = null;
					settings.filters["rentedUntil"] = null;
					break;
				case "categoryUuid":
					filter.categoryUuid = null;
					settings.filters["categoryUuid"] = null;
					break;
				case "modelUuid":
					filter.modelUuid = null;
					settings.filters["modelUuid"] = null;
					break;
			}
			setFilter(filter);
		}
		itemsPaging.updateSettings({
			...settings,
		});
		onPagingChange(itemsPaging.getSettings());
	};

	const filterOptions = (
		<Grid container spacing={1}>
			{!filterIsFiltering()
				&& (
					<Grid item>
						<Chip variant="outlined" label={t("noFilter")} onClick={() => setFilterDialog(true)} />
					</Grid>
				)}
			{(filter.needle && filter.needle.length > 0)
				&& (
					<Grid item>
						<Chip
							label={`${t("fulltextSearch")}: ${filter.needle}`}
							onDelete={() => handleResetSingleFilter("needle")}
						/>
					</Grid>
				)}
			{filter.rentalState
				&& (
					<Grid item>
						<Chip
							label={`${t("filterStates")}: ${filter.rentalState}`}
							onDelete={() => handleResetSingleFilter("rentalState")}
						/>
					</Grid>
				)}
			{filter.from
				&& (
					<Grid item>
						<Chip
							label={`${t("filterTimeframeFrom")}: ${convertPlainDateTime(filter.from)}`}
							onDelete={() => handleResetSingleFilter("from")}
						/>
					</Grid>
				)}
			{filter.until
				&& (
					<Grid item>
						<Chip
							label={`${t("filterTimeframeUntil")}: ${convertPlainDateTime(filter.until)}`}
							onDelete={() => handleResetSingleFilter("until")}
						/>
					</Grid>
				)}

			{filter.availableFrom
				&& (
					<Grid item>
						<Chip
							label={`${t("filterTimeframeFrom")}: ${convertPlainDateTime(filter.availableFrom)}`}
							onDelete={() => handleResetSingleFilter("from")}
						/>
					</Grid>
				)}
			{filter.availableUntil
				&& (
					<Grid item>
						<Chip
							label={`${t("filterTimeframeUntil")}: ${convertPlainDateTime(filter.availableUntil)}`}
							onDelete={() => handleResetSingleFilter("until")}
						/>
					</Grid>
				)}

			{filter.categoryUuid
				&& (
					<Grid item>
						<Chip
							label={`${t("filterCategory")}: ${filter.category?.name}`}
							onDelete={() => handleResetSingleFilter("categoryUuid")}
						/>
					</Grid>
				)}

			{filter.modelUuid
				&& (
					<Grid item>
						<Chip
							label={`${t("filterModel")}: ${filter.model?.name}`}
							onDelete={() => handleResetSingleFilter("modelUuid")}
						/>
					</Grid>
				)}
		</Grid>
	);

	const handleCancelFilterDialog = () => {
		setFilterDialog(false);
		onPagingChange(itemsPaging.getSettings());
	};

	const onPagingChange = (settings: PagingSettings<unknown>) => {
		loadPage(needle, filter).then();
	};

	return (
		<RentalAppContainer title={t("items.plural")}>
			<Grid container spacing={3} style={{justifyContent: "flex-end"}}>
				<Grid item style={{flexGrow: 1}}>
					<Breadcrumbs>
						<BreadcrumbItem>{t("items.plural")}</BreadcrumbItem>
					</Breadcrumbs>
					<Box my={1} />
					<Typography variant="h4">{t("items.plural")}</Typography>
				</Grid>
				<Grid item style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<TableSearchInput needle={filter.needle} label={t("actions.search")} onSubmit={handleSearch} />
					{canImport
						&& (
							<Fragment>
								<Box mx={2} />
								<PushButtonWithMenu
									label={t("items.import.title")}
									onClick={() => navigate("/items/import")}
									style={{minWidth: "250px"}}
								>
									<MenuItem onClick={() => setRegenerate(true)}>
										{t("items.generateCodes.title")}
									</MenuItem>
								</PushButtonWithMenu>
							</Fragment>
						)}
				</Grid>
			</Grid>
			<Box my={3} />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper>
						<ContentTable
							page={page}
							pageable={itemsPaging.getSettings()}
							onPageableChange={handlePageChange}
							onFilterClick={() => setFilterDialog(true)}
							renderFilterOptions={filterOptions}
							inProgress={inProgress}
							columns={columns}
							onColumnsChange={c => {
								console.log("handle change columns", c);
								setColumns(c);
							}}
							renderTableBody={
								<Fragment>
									{page && page.content.map(i => (
										<TableRow
											key={"item-" + i.uuid}
											onClick={() => navigate("/items/" + i.uuid)}
										>
											{showCell("name") && <TableCell>{i.name}</TableCell>}
											{showCell("foreignId") && <TableCell>{i.foreignId || "--"}</TableCell>}
											{showCell("model") && (
												<TableCell>{i.category ? i.category.name : "--"}</TableCell>
											)}
											{showCell("model") && (
												<TableCell>{i.model ? i.model.name : "--"}</TableCell>
											)}
											{showCell("stored.cubeId") && (
												<TableCell>
													<CubeDescriptionDisplay cubeId={i.stored ? i.stored.cubeId : ""} />
													{" "}
													{i.stored ? " / " + i.stored.boxNumber : "--"}
												</TableCell>
											)}
											{showCell("adminCode") && <TableCell>{i.adminCode || "--"}</TableCell>}
											{showCell("openCode") && <TableCell>{i.openCode || "--"}</TableCell>}
											{showCell("disabled") && (
												<TableCell>
													<ItemStateDisplay item={i} />
												</TableCell>
											)}
										</TableRow>
									))}
								</Fragment>
							}
						/>
					</Paper>
				</Grid>
			</Grid>
			{tenantUserStore.staff && <AddFab onClick={() => navigate("/items/create")} />}
			{error && <ErrorDialog error={error} onClose={() => setError(undefined)} />}
			<Dialog open={regenerate}>
				<DialogTitle>{t("items.generateCodes.title")}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t("items.generateCodes.message")}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => setRegenerate(false)}>{t("actions.cancel")}</Button>
					<Button color="primary" variant="contained" onClick={() => handleRegenerateAllItemCodes()}>
						{t("actions.confirm")}
					</Button>
				</DialogActions>
				{inProgress && <LinearProgress />}
			</Dialog>
			<RentalFilter
				filterDialog={filterDialog}
				setFilterDialog={setFilterDialog}
				handleFilter={handleFilter}
				handleResetFilter={handleResetFilter}
				handleCancelFilterDialog={handleCancelFilterDialog}
				filter={filter}
				setFilter={setFilter}
				allowNotRentedSearch
			/>
		</RentalAppContainer>
	);
};
