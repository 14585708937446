import {createElement} from "react";
import {Chip} from "@mui/material";
import {Item} from "../../data/types";
import {green, red} from "@mui/material/colors";
import {useLocalization} from "../../i18n";

export function ItemStateDisplay({item}: { item: Item }) {
	const {t} = useLocalization();
    return (
        item.disabled ?
            <Chip label={t('common.disabled')} style={{ color: '#fff', background: red[500] }}/> :
            <Chip label={t('common.enabled')} style={{ color: '#fff', background: green[500] }}/>
    );
}
