import {createElement, Fragment, useCallback, useState} from "react";
import {ChangeTenantRequest} from "../../data/types";
import {ApiError} from "../../Api";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Typography,
	Alert
} from "@mui/material";
import {
	Checkbox as AppCheckbox,
    TextField
} from "@variocube/app-ui";
import {tenantUserStore} from "../../store/TenantUserStore";
import {saveTenantSettings} from "../../data/tenants";
import {Loading} from "../../components/Loading";
import {PeriodInputComponent} from "../../components/PeriodInputComponent";
import {SmsSettings, SmsSettingsComponent} from "../../components/SmsSettingsComponent";
import {ErrorDialog} from "../../components/ErrorDialog";
import {CategoryMultiSelector} from "../categories";
import {useLocalization} from "../../i18n";
import {DeleteIcon} from "../../theme";

export const TenantSettings = () => {
    const tenant = tenantUserStore.tenant;
    const [tenantForm, setTenantForm] = useState<ChangeTenantRequest>({
        maxRentPeriod: tenant?.maxRentPeriod ?? null,
        maxRentals: tenant?.maxRentals ?? null,
        emailDomains: tenant?.emailDomains || [],
        signUpDomains: tenant?.signUpDomains || [],
        requireManuallyApproval: tenant?.requireManuallyApproval ?? false,
        enabledCostCenter: tenant?.enabledCostCenter ?? false,
        rentalEndsAfterReturn: tenant?.rentalEndsAfterReturn?? false,
        receiveEmails: tenant?.receiveEmails ?? false,
        whitelist: tenant?.whitelist ?? [],
        enabledTicketOnCube: tenant?.enabledTicketOnCube ?? false,
        sendSmsOnRental: tenant?.sendSmsOnRental ?? false,
        smsCountryCode: tenant?.smsCountryCode ?? "",
        smsTemplate: tenant?.smsTemplate ?? "",
        overdueReminderHours: tenant?.overdueReminderHours ?? 48,
        rentalInfo: tenant?.rentalInfo ?? {
            en: "",
            de: ""
        },
        reducedMenuOptions: tenant?.reducedMenuOptions ?? false,
        displayBorrowerData: tenant?.displayBorrowerData ?? false,
		requiredEmailVerificationForSignUp: tenant?.requiredEmailVerificationForSignUp ?? false,
		requiredPhoneForSignUp: tenant?.requiredPhoneForSignUp ?? false,
		requiredPhoneVerificationForSignUp: tenant?.requiredPhoneVerificationForSignUp ?? false,
		requiredAddressForSignUp: tenant?.requiredAddressForSignUp ?? true,
		requiredNameForSignUp: tenant?.requiredNameForSignUp ?? false,
		email: tenant?.email ?? '',
		phone: tenant?.phone ?? '',
    });
    const [tenantChangeSuccess, setTenantChangeSuccess] = useState(false);
    const [error, setError] = useState<ApiError>();

    const onPeriodChange = (hours: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                maxRentPeriod: hours
            });
        }
    };

	function onEmailChange(email: string) {
		setTenantForm({
			...tenantForm,
			email
		});
	}

	function onPhoneChange(phone: string) {
		setTenantForm({
			...tenantForm,
			phone
		});
	}

    const onMaxRentalsChange = (rentals: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                maxRentals: rentals
            });
        }
    };

    const onEmailDomainChange = (domain: string, i: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                emailDomains: tenantForm.emailDomains.map((d, di) => (di === i) ? domain : d)
            });
        }
    };

    const addEmailDomain = () => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                emailDomains: tenantForm.emailDomains.concat('')
            });
        }
    };

    const removeEmailDomain = (index: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                emailDomains: tenantForm.emailDomains.filter((d, i) => i !== index)
            })
        }
    }

    const onSignupDomainChange = (domain: string, i: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                signUpDomains: tenantForm.signUpDomains.map((d, di) => (di === i) ? domain : d)
            });
        }
    };

    const addSignupDomain = () => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                signUpDomains: tenantForm.signUpDomains.concat('')
            });
        }
    };

    const removeSignupDomain = (index: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                signUpDomains: tenantForm.signUpDomains.filter((d, i) => i !== index)
            })
        }
    }

    const onWhitelistChange = (whitelist: string[]) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                whitelist
            })
        }
    }

    const onRequireManuallyApproveChange = (event: any) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                requireManuallyApproval: event.target.checked
            });
        }
    };

    const onEnabledCostCenterChange = (event: any) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                enabledCostCenter: event.target.checked
            });
        }
    };

    const onRentalEndsAfterReturnChange = (event: any) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                rentalEndsAfterReturn: event.target.checked
            });
        }
    };

    const onEnabledTicketOnCubeChange = (event: any) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                enabledTicketOnCube: event.target.checked
            });
        }
    };

	function onRequiredEmailVerificationForSignUpChange(checked: boolean) {
		setTenantForm({
			...tenantForm,
			requiredEmailVerificationForSignUp: checked
		});
	}

	function onRequiredPhoneForSignUpChange(checked: boolean) {
		setTenantForm({
			...tenantForm,
			requiredPhoneForSignUp: checked
		});
	}

	function onRequiredPhoneVerificationForSignUpChange(checked: boolean) {
		setTenantForm({
			...tenantForm,
			requiredPhoneVerificationForSignUp: checked
		});
	}

	function onRequiredAddressForSignUpChange(checked: boolean) {
		setTenantForm({
			...tenantForm,
			requiredAddressForSignUp: checked
		});
	}

	function onRequiredNameForSignUpChange(checked: boolean) {
		setTenantForm({
			...tenantForm,
			requiredNameForSignUp: checked
		});
	}

    const onChangeSmsSettings = (smsSettings: SmsSettings) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                sendSmsOnRental: smsSettings.sendSms,
                smsCountryCode: smsSettings.countryCode,
                smsTemplate: smsSettings.template
            });
        }
    };

    const onChangeOverdueReminderHours = (hours: number) => {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                overdueReminderHours: hours
            });
        }
    };

    const onRentalInfoChange = useCallback((lang: string, info: string) => {
        setTenantForm(prev => ({
            ...prev,
            rentalInfo: {
                ...prev?.rentalInfo,
                [lang]: info
            }
        }));
    }, []);

	const onReducedMenuOptionsChange = (event: any) => {
		if (tenantForm) {
			setTenantForm({
				...tenantForm,
				reducedMenuOptions: event.target.checked
			});
		}
	};

	const onReceiveEmailsChange = (event: any) => {
		if(tenantForm) {
			setTenantForm({
				...tenantForm,
				receiveEmails: event.target.checked
			});
		}
	}

    function onDisplayBorrowerDataChange(ev: any) {
        if (tenantForm) {
            setTenantForm({
                ...tenantForm,
                displayBorrowerData: ev.target.checked
            })
        }
    }

    const save = () => {
        if (tenantForm) {
            saveTenantSettings(tenantUserStore.getTenantId(), tenantForm)
                .then(t => {
                    tenantUserStore.setTenant(t);
                    setTenantChangeSuccess(true);
                    setTimeout(() => {
                        setTenantChangeSuccess(false);
                    }, 1500);
                })
                .catch(setError);
        }
    };

	const {t} = useLocalization();
    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4">
                        {t('settings.tenantTitle')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            {!tenantForm && (
                                <Loading/>
                            )}
                            {tenantForm && (
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6} >
											<Grid container spacing={3}>
												<Grid item xs={12} >
													<PeriodInputComponent label={t('rentals.maxRentPeriod')}
																		  hours={tenantForm.maxRentPeriod}
																		  onPeriodChange={onPeriodChange}/>
												</Grid>
												<Grid item xs={12} >
													<PeriodInputComponent label={t('tenants.overdueReminderHours')}
																		  hours={tenantForm.overdueReminderHours}
																		  onPeriodChange={onChangeOverdueReminderHours}/>
												</Grid>
												<Grid item xs={12} >
													<TextField variant="outlined" label={t('rentals.maxRentals.title')}
															   value={tenantForm.maxRentals || ''}
															   onChange={e => onMaxRentalsChange(e as any)} />
													<Box my={1} />
													<Typography variant="body2"
																color="textSecondary">{t('rentals.maxRentals.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.requireManuallyApproval}
																		   onChange={onRequireManuallyApproveChange}/>}
														label={t('settings.requireManuallyApproved.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.requireManuallyApproved.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.reducedMenuOptions}
																		   onChange={onReducedMenuOptionsChange}/>}
														label={t('settings.reducedMenuOptions.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.reducedMenuOptions.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.enabledCostCenter}
																		   onChange={onEnabledCostCenterChange}/>}
														label={t('settings.enableCostCenter.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.enableCostCenter.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.rentalEndsAfterReturn}
																		   onChange={onRentalEndsAfterReturnChange}/>}
														label={t('settings.rentalEndsAfterReturn.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.rentalEndsAfterReturn.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.enabledTicketOnCube}
																		   onChange={onEnabledTicketOnCubeChange}/>}
														label={t('settings.enabledTicketOnCube.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.enabledTicketOnCube.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.receiveEmails}
																		   onChange={onReceiveEmailsChange}/>}
														label={t('settings.receiveEmails.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.receiveEmails.helperText')}</Typography>
												</Grid>
												<Grid item xs={12}>
													<Box my={2}/>
													<FormControlLabel
														control={<Checkbox checked={tenantForm.displayBorrowerData}
																		   onChange={onDisplayBorrowerDataChange}/>}
														label={t('settings.displayBorrowerData.label')}
													/>
													<Typography variant="body2"
																color="textSecondary">{t('settings.displayBorrowerData.helperText')}</Typography>
												</Grid>
												<Grid item xs={11}>
													<Box my={2}/>
													<CategoryMultiSelector label={t('settings.whitelist')}
																		   helperText={t('settings.whitelistsHint')}
																		   value={tenantForm.whitelist ?? []}
																		   onChange={onWhitelistChange}/>
												</Grid>
											</Grid>
                                        </Grid>
                                        <Grid item xs={6}>
											<SmsSettingsComponent
												onChangeSettings={onChangeSmsSettings}
												checkBoxLabel={t('settings.sendSmsOnRentalToBorrower.label')}
												stdSmsText={t('settings.sendSmsOnRentalToBorrowerStdTemplate', {
													tenant: {
														name: '{{tenant.name}}'
													},
													rental: {
														itemName: '{{rental.itemName}}',
														borrower: {
															name: '{{rental.borrower.name}}',
															accessCode: '{{rental.borrower.accessCode}}'
														}
													},
													from: '{{from}}',
													until: '{{until}}'
												})}
												smsSettings={{
													sendSms: tenantForm.sendSmsOnRental,
													countryCode: tenantForm.smsCountryCode,
													template: tenantForm.smsTemplate
												}}
											/>
                                        </Grid>
                                    </Grid>
                                    <Box my={3}/>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={tenantForm.rentalInfo.de}
                                                variant="outlined" multiline rows={2}
                                                onChange={(event) => onRentalInfoChange('de', event)}
                                                fullWidth label={t('settings.rentalAdditionalInfoGerman')}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={tenantForm.rentalInfo.en}
                                                variant="outlined" multiline rows={2}
                                                onChange={(event) => onRentalInfoChange('en', event)}
                                                fullWidth label={t('settings.rentalAdditionalInfoEnglish')}/>
                                        </Grid>
                                    </Grid>
									<Box my={3} />
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												value={tenantForm.email}
												variant="outlined"
												onChange={onEmailChange}
												fullWidth label={t('settings.supportEmail')}/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												value={tenantForm.phone}
												variant="outlined"
												onChange={onPhoneChange}
												fullWidth label={t('settings.supportPhone')}/>
										</Grid>
									</Grid>
                                    <Grid>
                                        <Box my={3}><Divider/></Box>
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item sm={6} xs={12}>
                                                    <Typography
                                                        variant="h6">{t('settings.emailDomains')}</Typography>
                                                    {tenantForm.emailDomains.map((d, i) => (
                                                        <Box my={1} key={'email-domain-' + i} display="flex">
                                                            <TextField variant="outlined" fullWidth label="Domain"
                                                                       value={d}
                                                                       onChange={e => onEmailDomainChange(e, i)}/>
                                                            <Box mx={1}/>
                                                            <IconButton
                                                                onClick={() => removeEmailDomain(i)}><DeleteIcon/></IconButton>
                                                        </Box>
                                                    ))}
                                                    <Button variant="outlined" color="primary"
                                                            onClick={addEmailDomain}>{t('settings.addDomain')}</Button>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <Typography
                                                        variant="h6">{t('settings.signUpDomains')}</Typography>
                                                    {tenantForm.signUpDomains.map((d, i) => (
                                                        <Box my={1} key={'signup-domain-' + i} display="flex">
                                                            <TextField variant="outlined" fullWidth label="Domain"
                                                                       value={d}
                                                                       onChange={e => onSignupDomainChange(e, i)}/>
                                                            <Box mx={1}/>
                                                            <IconButton
                                                                onClick={() => removeSignupDomain(i)}><DeleteIcon/></IconButton>
                                                        </Box>
                                                    ))}
                                                    <Button variant="outlined" color="primary"
                                                            onClick={addSignupDomain}>{t('settings.addDomain')}</Button>
                                                </Grid>
                                            </Grid>

											<Grid container spacing={3}>
												<Grid item xs={12}>
													<AppCheckbox
														label={t('settings.signUpRequireEmailVerification')}
														checked={tenantForm.requiredEmailVerificationForSignUp}
														onChange={onRequiredEmailVerificationForSignUpChange}
													/>
												</Grid>
												<Grid item xs={12}>
													<AppCheckbox
														label={t('settings.signUpRequirePhone')}
														checked={tenantForm.requiredPhoneForSignUp}
														onChange={onRequiredPhoneForSignUpChange}
													/>
												</Grid>
												<Grid item xs={12}>
													<AppCheckbox
														label={t('settings.signUpRequirePhoneVerification')}
														checked={tenantForm.requiredPhoneVerificationForSignUp}
														onChange={onRequiredPhoneVerificationForSignUpChange}
													/>
												</Grid>
												<Grid item xs={12}>
													<AppCheckbox
														label={t('settings.signUpRequireAddress')}
														checked={tenantForm.requiredAddressForSignUp}
														onChange={onRequiredAddressForSignUpChange}
													/>
												</Grid>
												<Grid item xs={12}>
													<AppCheckbox
														label={t('settings.signUpRequireName')}
														checked={tenantForm.requiredNameForSignUp}
														onChange={onRequiredNameForSignUpChange}
													/>
												</Grid>
											</Grid>
                                        </Grid>
                                    </Grid>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button variant="contained" color="primary"
                                                onClick={save}>{t('actions.save')}</Button>
                                    </Box>
                                    {tenantChangeSuccess && (
                                        <Fragment>
                                            <Box my={1}/>
                                            <Alert severity="success">
                                                {t('settings.changeSuccess')}
                                            </Alert>
                                        </Fragment>
                                    )}
                                </div>
                            )}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)}/>
            )}
		</Fragment>
    )
}
