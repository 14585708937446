import { DateTime } from "luxon";
import { createElement, Fragment, useCallback, useEffect, useState } from "react";
import {
    Alert,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Link,
    MenuItem,
    Paper,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    BreadcrumbItem,
    Breadcrumbs,
    Page,
    PagingImpl,
    PagingSettings,
    Selector
} from "@variocube/app-ui";
import { ApiError } from "../../Api";
import {
    Item,
    ItemAvailabilityResponse,
    ItemDetails,
    Rental,
    Ticket,
    TicketCreateRequest,
    TicketType
} from "../../data/types";
import {
    deleteItem,
    getItem,
    itemAvailability,
    itemClearCodes,
    itemGenerateCodes,
    listItemsTickets,
    updateItem
} from "../../data/items";
import { createTicket } from "../../data/tickets";
import { listRentals, listRentalsCalendar } from "../../data/rentals";
import { Loading } from "../../components/Loading";
import { LabeledData } from "../../components/LabeledData";
import { ErrorDialog } from "../../components/ErrorDialog";
import { tenantUserStore } from "../../store/TenantUserStore";
import { FormattedAlert } from "../../components/FormattedAlert";
import { ImageWithPopover } from "../../components/ImageWithPopover";
import { DateComponent, Display } from "../../components/DateComponent";
import { ItemAvailabilityDisplay } from "./ItemAvailabillityDisplay";
import { CategoryLink } from "../categories";
import { RentalsCalendar, RentalTable } from "../rentals";
import { getRentalTriggerUrlWithDateRangeAndItem, getRentalTriggerUrlWithItemUuid } from "../rentals/trigger";
import { ItemStateDisplay } from "./ItemStateDisplay";
import { ItemActions } from "./ItemActions";
import { ItemForm } from "./ItemForm";
import { useLocalization } from "../../i18n";
import { AssistantIcon, BrokenImageIcon, BuildIcon, HelpIcon, InfoIcon } from "../../theme";
import { grey, red } from "@mui/material/colors";
import { RentalAppContainer } from "../../RentalAppContainer";
import { CrumbLink } from "../../components/CrumbLink";
import { CubeDescriptionDisplay } from "../../components/CubeDescriptionDisplay";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ItemStoreDialog } from "./ItemStoreDialog";
import { removeItem, storeItem } from "../../data/cubes";
import { ItemRemoveDialog } from "./ItemRemoveDialog";

const ticketIconSx = {
    fontSize: 'inherit',
    marginRight: 10,
    top: 2,
    position: 'relative'
}

const paging = new PagingImpl('irentals_paging');

export const ItemView = () => {
    const { t, e } = useLocalization();
    const navigate = useNavigate();
    const { uuid = '' } = useParams<{ uuid: string }>();

    const [item, setItem] = useState<Item>();
    const [availability, setAvailability] = useState<ItemAvailabilityResponse>();
    const [details, setDetails] = useState<ItemDetails>();
    const [rentals, setRentals] = useState<Rental[]>([]);
    const [rentalPage, setRentalPage] = useState<Page<Rental>>();
    const [processing, setProcessing] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [hasRentals, setHasRentals] = useState(false);
    const [hasActiveRentals, setHasActiveRentals] = useState(false);
    const [isStored, setIsStored] = useState(false);
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [ticketForm, setTicketForm] = useState<TicketCreateRequest>();
    const [reportMode, setReportMode] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [rentalMode, setRentalMode] = useState<'calendar' | 'list'>('calendar');
    const [error, setError] = useState<ApiError>();

    const [itemStoreDialogOpen, setItemStoreDialogOpen] = useState(false);
    const [itemRemoveDialogOpen, setItemRemoveDialogOpen] = useState(false);

    const applyDetails = (item: Item) => {
        setDetails(JSON.parse(JSON.stringify({
            foreignId: item.foreignId,
            name: item.name,
            description: item.description,
            images: item.images,
            modelUuid: item.model ? item.model.uuid : '',
            maxRentPeriod: item.maxRentPeriod || '' as any,
            amount: item.amount ? JSON.parse(JSON.stringify(item.amount)) : null,
            calculationCase: item.calculationCase,
            disabled: item.disabled,
            needInspection: item.needInspection,
            categoryUuid: item.category ? item.category.uuid : ''
        })));
    }

    const loadRentals = useCallback((from?: DateTime, until?: DateTime) => {
        const now = DateTime.now();
        if (!from) from = now.set({ day: 1 });
        if (!until) until = now.set({ day: now.daysInMonth });
        listRentalsCalendar(tenantUserStore.getTenantId(), from, until, undefined, uuid)
            .then(setRentals)
            .catch(setError);
    }, [setRentals, setError]);

    const loadRentalPage = useCallback(() => {
        listRentals(tenantUserStore.getTenantId(), paging, undefined, undefined, uuid)
            .then(setRentalPage)
            .catch(setError);
    }, [setRentalPage, setError])

    useEffect(() => {
        loadItem();
    }, []);

    const loadItem = () => {
        getItem(tenantUserStore.getTenantId(), uuid)
            .then(item => {
                setItem(item);
                applyDetails(item);
                setTicketForm({
                    type: TicketType.Defective,
                    itemUuid: item.uuid,
                    rentalUuid: null,
                    comment: ''
                });
                itemAvailability(tenantUserStore.getTenantId(), uuid).then(setAvailability);
                if (tenantUserStore.staff) {
                    listItemsTickets(tenantUserStore.getTenantId(), uuid)
                        .then(tickets => setTickets(tickets));
                }
                if (!item.disabled) {
                    loadRentals();
                }
            })
            .catch(toggleError);
    }

    const toggleDelete = async () => {
        await checkDeletebility();
        setDeleteDialog(!deleteDialog);
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleRentalModeChange = (mode: 'calendar' | 'list') => {
        setRentalMode(mode);
        switch (mode) {
            case 'calendar':
                loadRentals();
                break;
            case 'list':
                loadRentalPage();
                break;
        }
    }

    const update = () => {
        if (details) {
            setProcessing(true);
            updateItem(tenantUserStore.getTenantId(), uuid, details)
                .then(i => {
                    setItem(i);
                    applyDetails(i);
                    setEditMode(false);
                    setProcessing(false);
                })
                .catch(toggleError);
        }
    };

    const handleGenerateCodes = () => {
        setProcessing(true);
        itemGenerateCodes(tenantUserStore.getTenantId(), uuid)
            .then((item) => {
                setItem(item);
                applyDetails(item);
                setProcessing(false);
            })
            .catch(toggleError);
    };

    const handleClearCodes = () => {
        setProcessing(true);
        itemClearCodes(tenantUserStore.getTenantId(), uuid)
            .then((item) => {
                setItem(item);
                applyDetails(item);
                setProcessing(false);
            })
            .catch(toggleError);
    };

    const checkDeletebility = async () => {
        if (item?.stored)
            setIsStored(true);
        else {
            const pagingNone = new PagingImpl('no_paging');
            let setting = pagingNone.getSettings();
            setting.pageSize = 100000;
            pagingNone.updateSettings(setting);
            let rentals: Rental[] = (await listRentals(tenantUserStore.getTenantId(), pagingNone, undefined, undefined, uuid)).content;
            let active = rentals.filter(r => r.until >= DateTime.now());
            if (active.length > 0)
                setHasActiveRentals(true);
            else if (rentals.length > 0)
                setHasRentals(true);
        }
    };

    const handleDelete = () => {
        if (item && item.uuid) {
            deleteItem(tenantUserStore.getTenantId(), item.uuid)
                .then(() => navigate('/items'))
                .catch(toggleError);
        }
    };

    const toggleReportMode = () => {
        setReportMode(!reportMode);
    };

    const onReportFormChange = (event: any) => {
        if (ticketForm) {
            const { name, value } = event.target;
            setTicketForm({
                ...ticketForm,
                [name]: value
            });
        }
    };

    const handleCreateTicket = () => {
        if (ticketForm) {
            createTicket(tenantUserStore.getTenantId(), ticketForm)
                .then(t => navigate('/tickets/' + t.uuid, { replace: true }))
                .catch(toggleError);
        }
    };

    const toggleError = (error?: ApiError) => {
        setError(error);
    };

    const handleSelectRentalByUuid = (rentalUuid: string) => {
        if (rentals && rentals.length > 0) {
            const rental = rentals.find(r => r.uuid === rentalUuid);
            if (rental) {
                navigate(`/rentals/${rental.uuid}`);
            }
        }
    }

    const handleSelectSlot = (from: DateTime, until: DateTime) => {
        navigate(getRentalTriggerUrlWithDateRangeAndItem(from, until, uuid));
    }

    const handleRentalPageChange = (settings: PagingSettings<any>) => {
        paging.updateSettings({
            ...paging.getSettings(),
            ...settings
        });
        loadRentalPage();
    }

    const renderMaxRentPeriod = ({ maxRentPeriod, model }: Item) => {
        let inheritFromModel = false;
        if (!maxRentPeriod && model && model.maxRentPeriod) {
            maxRentPeriod = model.maxRentPeriod;
            inheritFromModel = true;
        }
        let unit = maxRentPeriod ? (maxRentPeriod > 1 ? t('period.plural.Hours') : t('period.singular.Hours')) : undefined;
        if (maxRentPeriod && unit) {
            if (inheritFromModel) {
                return (
                    <Tooltip title={t('items.inheritFromModel')} placement="top">
                        <Box display="flex" flexDirection="row">
                            {maxRentPeriod} {unit}
                            <Box mx={0.5} />
                            <InfoIcon fontSize="small" />
                        </Box>
                    </Tooltip>
                )
            }
            return <div>{maxRentPeriod} {unit}</div>
        }
        return (<span>--</span>)
    }

    const renderCost = ({ amount, calculationCase, model }: Item) => {
        if (amount && calculationCase) {
            return <div>{`€ ${amount.gross} / ${e('calculationCase', calculationCase)}`}</div>;
        }
        if (model && model.amount && model.calculationCase) {
            return (
                <Tooltip title={t('items.inheritFromModel')} placement="top">
                    <Box display="flex" flexDirection="row">
                        € {model.amount.gross} / {e('calculationCase', model.calculationCase)}
                        <Box mx={0.5} />
                        <InfoIcon fontSize="small" />
                    </Box>
                </Tooltip>
            )
        }
        return (<span>--</span>)
    }

    const storeItemStart = async () => {
        if (item?.stored) {
            console.info("Item already stored!");
            return;
        }
        setItemStoreDialogOpen(true);
    };

    function handleItemDialogClose() {
        setItemStoreDialogOpen(false);
		setItemRemoveDialogOpen(false);
    }

    function handleItemDialogResult() {
		console.log('handle dialog result');
        loadItem();
		setItemRemoveDialogOpen(false);
    }

    const removeItemStart = async () => {
        if (!item?.stored) {
            console.info("Item is not stored!");
            return;
        }
        setItemRemoveDialogOpen(true);
    }


    return (
        <RentalAppContainer title={item ? item.name : t('items.singular')}>
            {!item && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {(item && details) && (
                <Fragment>
                    <Grid container spacing={3} style={{ justifyContent: 'flex-end' }}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Breadcrumbs>
                                <CrumbLink href='/items'>{t('items.plural')}</CrumbLink>
                                <BreadcrumbItem>{item.name}</BreadcrumbItem>
                            </Breadcrumbs>
                            <Box my={1} />
                            <Typography variant="h4">
                                {t('items.singular')}: {item.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ItemActions item={item}
                                onCreateRental={() => navigate(getRentalTriggerUrlWithItemUuid(item.uuid))}
                                onEditItem={toggleEditMode}
                                onDeleteItem={toggleDelete}
                                onStoreItem={storeItemStart}
                                onRemoveItem={removeItemStart}
                            />
                        </Grid>
                    </Grid>
                    <Box my={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <Box p={3}>
                                    {!editMode && (
                                        <Grid container spacing={3}>
                                            <Grid item md={8}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item>
                                                                <LabeledData label={t('common.state')}>
                                                                    <ItemStateDisplay item={item} /> {item.needInspection && <Chip icon={<InfoIcon style={{ color: '#fff' }} />} style={{ color: '#fff', background: red[500] }} label={t('items.needInspection.title')} />}
                                                                </LabeledData>
                                                            </Grid>
                                                            {availability &&
                                                                <Grid item>
                                                                    <LabeledData label={t('items.availability.title')}>
                                                                        <ItemAvailabilityDisplay availability={availability.availability} rentalUuid={availability.rentalUuid} />
                                                                    </LabeledData>
                                                                </Grid>
                                                            }
                                                            <Grid item>
                                                                <LabeledData label={t('categories.singular')}>
                                                                    {item.category && !item.model && <CategoryLink category={item.category} />}
                                                                    {item.category && item.model && (
                                                                        <Tooltip title={t('items.inheritFromModel')} placement="top">
                                                                            <Box display="flex" flexDirection="row">
                                                                                <CategoryLink category={item.category} />
                                                                                <Box mx={0.5} />
                                                                                <InfoIcon fontSize="small" />
                                                                            </Box>
                                                                        </Tooltip>
                                                                    )}
                                                                    {!item.category && '--'}
                                                                </LabeledData>
                                                            </Grid>
                                                            <Grid item>
                                                                <LabeledData label={t('models.singular')}>
                                                                    {item.model ? (
                                                                        <Link href={'/models/' + item.model.uuid}>{item.model.name}</Link>
                                                                    ) : '--'}
                                                                </LabeledData>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item>
                                                                <LabeledData label={tenantUserStore.tenant?.centerId == 'hkl-baumaschinen' ? 'HKL-ID' : t('items.foreignId')}>
                                                                    {item.foreignId || '--'}
                                                                </LabeledData>
                                                            </Grid>
                                                            <Grid item style={{ flexGrow: 1 }}>
                                                                <LabeledData label={t('common.name')}>
                                                                    {item.name}
                                                                </LabeledData>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LabeledData label={t('common.description')}>
                                                            {item.description || '--'}
                                                        </LabeledData>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                {!item.stored ?
                                                                    <FormattedAlert severity="error" title={t('items.notStored.title')} info={t('items.notStored.info')} /> :
                                                                    <LabeledData label={`${t('cubes.singular')} / ${t('boxes.singular')}`}>
                                                                        <CubeDescriptionDisplay cubeId={item.stored ? item.stored.cubeId : ""} /> {item.stored ? ' / ' + item.stored.boxNumber : '--'}
                                                                    </LabeledData>
                                                                }
                                                            </Grid>
                                                            {tenantUserStore.staff &&
                                                                <Grid item xs={12} sm={6} md={6}>
                                                                    <Typography variant="h6">{t('items.codes.title')}</Typography>
                                                                    {item.adminCode &&
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={4}>
                                                                                <LabeledData label={t('items.codes.adminCode')}>
                                                                                    {item.adminCode}
                                                                                </LabeledData>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <LabeledData label={t('items.codes.openCode')}>
                                                                                    {item.openCode}
                                                                                </LabeledData>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <LabeledData label={t('items.codes.generatedAt')}>
                                                                                    {item.accessCodeGenerated &&
                                                                                        <DateComponent
                                                                                            date={item.accessCodeGenerated}
                                                                                            display={Display.Timestamp} />
                                                                                    }
                                                                                </LabeledData>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Grid container spacing={3}>
                                                                                    <Grid item>
                                                                                        <Button variant="outlined" onClick={handleGenerateCodes}>{t('items.codes.regenerate')}</Button>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Button variant="outlined" onClick={handleClearCodes}>{t('items.codes.clear')}</Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                    {!item.adminCode &&
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="body1">{t('items.codes.noCodes')}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Button variant="outlined" onClick={handleGenerateCodes}>{t('items.codes.generate')}</Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={3}>
                                                            <Grid item>
                                                                <LabeledData label={t('rentals.maxRentPeriod')}>
                                                                    {renderMaxRentPeriod(item)}
                                                                </LabeledData>
                                                            </Grid>
                                                            <Grid item>
                                                                <LabeledData label={t('items.cost.title')}>
                                                                    {renderCost(item)}
                                                                </LabeledData>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={4} xs={12}>
                                                {item.images.length === 0 && (
                                                    <Box p={3} style={{ background: grey[100], width: '100%' }}>
                                                        <Typography variant="overline">No image</Typography>
                                                    </Box>
                                                )}
                                                {item.images.length > 0 && (
                                                    <ImageWithPopover src={item.images[0]} />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    {editMode && (
                                        <div>
                                            <ItemForm details={details} onChange={setDetails} />
                                            <Box my={3} />
                                            <Box display="flex" justifyContent="flex-end">
                                                <Button variant="contained" onClick={toggleEditMode}>{t('actions.cancel')}</Button>
                                                <Box mx={1} />
                                                <Button variant="contained" color="primary" disabled={processing} onClick={update}>{t('actions.save')}</Button>
                                            </Box>
                                        </div>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                        {!editMode && (
                            <Grid item xs={12}>
                                <Paper>
                                    <Tabs value={tabIndex} onChange={(e, v) => setTabIndex(v)}>
                                        <Tab label={t('rentals.plural')} />
                                        {tenantUserStore.staff && (<Tab label={t('tickets.plural')} />)}
                                    </Tabs>
                                    <Divider />
                                    {(tabIndex === 0) && (
                                        <Box p={2}>
                                            {!item.disabled && (
                                                <Fragment>
                                                    <Grid container>
                                                        <Grid item xs={12} sm={3}>
                                                            <Selector label={t('rentals.listView.label')} size="small"
                                                                value={rentalMode} onChange={v => handleRentalModeChange(v as any)}
                                                                options={[
                                                                    { label: t('rentals.listView.Calendar'), value: 'calendar' },
                                                                    { label: t('rentals.listView.List'), value: 'list' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Box my={2} />
                                                    {rentalMode === 'calendar' && (
                                                        <RentalsCalendar rentals={rentals}
                                                            onRangeChanged={loadRentals}
                                                            onSelectRental={handleSelectRentalByUuid}
                                                            onSelectSlot={handleSelectSlot} />
                                                    )}
                                                    {rentalMode === 'list' && rentalPage !== undefined && (
                                                        <RentalTable page={rentalPage} pageable={paging.getSettings()}
                                                            onPagingChange={handleRentalPageChange} />
                                                    )}
                                                </Fragment>
                                            )}
                                            {item.disabled && (
                                                <FormattedAlert severity="warning" title={t('items.disable.title')} info={t('items.disable.info')} />
                                            )}
                                        </Box>
                                    )}
                                    {tabIndex === 1 && (
                                        <Box p={3}>
                                            <Typography variant="body1" gutterBottom>{t('tickets.warningReportHint')}</Typography>
                                            <Box my={2} />
                                            {tickets.map(ticket => (
                                                <Box my={2} key={ticket.uuid}>
                                                    <Paper variant="outlined">
                                                        <Box p={1}>
                                                            <Grid container spacing={1} key={ticket.uuid}>
                                                                <Grid item style={{ flexGrow: 1 }}>
                                                                    <Typography variant="subtitle1">Ticket-Id: {ticket.uuid}</Typography>
                                                                    {new Date(ticket.createdAt).toLocaleDateString()} <Chip size="small" label={e('tickets.types', ticket.type)} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button variant="outlined" color="primary" onClick={() => navigate('/tickets/' + ticket.uuid)}>{t('actions.view')}</Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                </Box>
                                            ))}
                                            <Box my={3} />
                                            <Button variant="contained" color="secondary" onClick={toggleReportMode}>{t('tickets.create.title')}</Button>
                                        </Box>
                                    )}
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                    <Dialog open={deleteDialog}>
                        <DialogTitle>{t('items.delete.title')}</DialogTitle>
                        <DialogContent>
                            <Typography>{t('items.delete.message', { itemName: item.name })}</Typography>
                            <Box my={1} />
                            <Alert severity="error">
                                <Typography>{t('items.delete.warning')}</Typography>
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => setDeleteDialog(false)}>{t('actions.cancel')}</Button>
                            <Button variant="contained" color="primary" onClick={handleDelete}>{t('actions.delete')}</Button>
                        </DialogActions>
                    </Dialog>
                    {ticketForm && reportMode && (
                        <Dialog open={true} onClose={toggleReportMode} fullWidth maxWidth="sm">
                            <DialogTitle>{t('tickets.warningReport')}</DialogTitle>
                            <DialogContent>
                                <TextField variant="outlined" fullWidth label={t('common.type')}
                                    name="type" select
                                    value={ticketForm.type}
                                    onChange={onReportFormChange}
                                >
                                    <MenuItem value={TicketType.Defective}>
                                        <BrokenImageIcon sx={ticketIconSx} />
                                        {t('tickets.types.Defective')}
                                    </MenuItem>
                                    <MenuItem value={TicketType.Lost}>
                                        <HelpIcon sx={ticketIconSx} />
                                        {t('tickets.types.Lost')}
                                    </MenuItem>
                                    <MenuItem value={TicketType.Maintenance}>
                                        <BuildIcon sx={ticketIconSx} />
                                        {t('tickets.types.Maintenance')}
                                    </MenuItem>
                                    <MenuItem value={TicketType.Misc}>
                                        <AssistantIcon sx={ticketIconSx} />
                                        {t('tickets.types.Misc')}
                                    </MenuItem>
                                </TextField>
                                <Box my={2} />
                                <TextField variant="outlined" fullWidth label={t('tickets.comment')}
                                    name="comment" multiline
                                    minRows={4} maxRows={10}
                                    defaultValue={ticketForm.comment}
                                    onBlur={onReportFormChange}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={toggleReportMode}>{t('actions.cancel')}</Button>
                                <Button color="primary" variant="contained" onClick={handleCreateTicket}>{t('actions.create')}</Button>
                            </DialogActions>
                        </Dialog>
                    )}
                    {itemStoreDialogOpen && <ItemStoreDialog item={item} handleClose={handleItemDialogClose} handleStore={handleItemDialogResult} />}
                    {itemRemoveDialogOpen && <ItemRemoveDialog item={item} handleClose={handleItemDialogClose} handleRemove={handleItemDialogResult} />}
                </Fragment>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </RentalAppContainer>
    );
};
