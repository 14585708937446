import { createElement, Fragment } from "react";
import { Box } from "@mui/material";
import { tenantUserStore } from "../../store/TenantUserStore";
import { MySettings } from "./MySettings";
import { TenantSettings } from "./TenantSettings";
import { useLocalization } from "../../i18n";
import { RentalAppContainer } from "../../RentalAppContainer";
import { BreadcrumbItem, Breadcrumbs } from "@variocube/app-ui";

export const Settings = () => {
    const { t } = useLocalization();
    return (
        <RentalAppContainer title={t('settings.title')}>
            <Breadcrumbs>
                <BreadcrumbItem>{t('settings.title')}</BreadcrumbItem>
            </Breadcrumbs>
            <Box my={1} />
            <MySettings />
            <Box my={3} />
            {tenantUserStore.admin && (
                <Fragment>
                    <TenantSettings />
                </Fragment>
            )}
        </RentalAppContainer>
    )
}
