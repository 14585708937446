import {createElement, useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from "@mui/material";
import {useLocalization} from "../i18n";

type SmsSettingsComponentProps = {
    checkBoxLabel: string;
    smsSettings: SmsSettings;
    stdSmsText: string;
    onChangeSettings: (smsSettings: SmsSettings) => void;
}

export type SmsSettings = {
    sendSms: boolean;
    countryCode: string;
    template: string
}


export const SmsSettingsComponent = (props: SmsSettingsComponentProps) => {
	const {t} = useLocalization();

    const [smsSettings, setSmsSettings] = useState<SmsSettings>(props.smsSettings);
    const [stdSmsText, setStdSmsText] = useState<boolean>(false);

    useEffect(() => {
        onStdSmsTextChanged(props.smsSettings?.template?.length === 0);
    }, []);


    const onSendSmsChanged = (event: any) => {
        const data = {
            ...smsSettings,  sendSms: event.target.checked,
        };

        setSmsSettings(data);
        props.onChangeSettings(data);
    };

    const onStdSmsTextChanged= (checked: boolean) => {
        setStdSmsText(checked);
        if (checked) {
            const data = {
                ...smsSettings,  template: props.stdSmsText
            };
            setSmsSettings(data);
            props.onChangeSettings(data);
        }
    };

    const onCountryCodeChanged = (event: any) => {
        let countryCode = event.target.value;

        let validChar: boolean =  countryCode.match('^[0-9+]*$');
        if ( validChar) {
            const data = {
                ...smsSettings, countryCode: countryCode,
            };
            setSmsSettings(data);
            props.onChangeSettings(data);
        }
    };



    const onTemplateChanged = (event: any) => {
        let template = event.target.value;
        const data = {
             ...smsSettings, template: template,
        };
        setSmsSettings(data);
        props.onChangeSettings(data);
    };


    return (
        <Grid container spacing={3} >
            <Grid item xs={12}  >
                <Box my={2}/>
                <FormControlLabel
                    control={<Checkbox checked={smsSettings.sendSms} onChange={onSendSmsChanged} />}
                    label={props.checkBoxLabel}
                />
            </Grid>
			<Grid item xs={12}  >
                <TextField inputProps={{
                    maxLength: 4,
                    placeholder:'+43'
                }} disabled={!smsSettings.sendSms} variant="outlined" fullWidth label={t('settings.smsCountryCode')} onChange={onCountryCodeChanged}  value={smsSettings.countryCode ?? ''}/>
            </Grid>

            <Grid item xs={12}   >
                <Box my={4}/>
                <FormControlLabel disabled={!smsSettings.sendSms}
                    control={<Checkbox checked={stdSmsText} onChange={e => onStdSmsTextChanged(e.target.checked)} />}
                    label={t('settings.standardSmsText')}
                />
            </Grid>

            <Grid item xs={12} >
                <TextField  inputProps={{ style: { fontFamily:"monospace",  fontSize: "smaller"}}}
                               disabled={!smsSettings.sendSms || stdSmsText} variant="outlined"  multiline onChange={onTemplateChanged} value={smsSettings.template ?? ''}

                             fullWidth label={t('settings.smsText')} />
            </Grid>
        </Grid>
    )
};
