import { createElement, Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, Paper, TextField, Typography, Alert } from "@mui/material";
import { ApiError } from "../../Api";
import { tenantUserStore } from "../../store/TenantUserStore";
import { changePassword, deleteUserRequest } from "../../data/users";
import { ChangeUserPasswordRequest, User } from "../../data/types";
import { Loading } from "../../components/Loading";
import { ErrorDialog } from "../../components/ErrorDialog";
import { useLocalization } from "../../i18n"; 
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { useNavigate } from "react-router-dom";

export const MySettings = () => {
    const [user, setUser] = useState<User>();
    const [passwordForm, setPasswordForm] = useState<ChangeUserPasswordRequest>({
        password: '',
        confirmPassword: ''
    });
    const [success, setSuccess] = useState(false);
    const [formError, setFormError] = useState(false);
    const [error, setError] = useState<ApiError>();
    const [deleteMode, setDeleteMode] = useState(false);
    const { t } = useLocalization();
    const navigate = useNavigate();

    useEffect(() => {
        tenantUserStore.load()
            .then(setUser)
            .catch(setError);
    }, [setUser]);

    const refreshAccessCode = () => {
        if (user) {
            tenantUserStore.refreshAccessCode()
                .then(u => {
                    setUser({
                        ...user,
                        accessCode: u.accessCode
                    });
                })
                .catch(setError);
        }
    };

    const onPasswordFormChange = (event: any) => {
        const { name, value } = event.target;
        setPasswordForm({
            ...passwordForm,
            [name]: value
        });
    };

    const handleChangePassword = () => {
        setSuccess(false);
        if (passwordForm.password !== passwordForm.confirmPassword) {
            setFormError(true);
            return;
        }
        changePassword(tenantUserStore.getTenantId(), passwordForm)
            .then(() => {
                setPasswordForm({
                    password: '',
                    confirmPassword: ''
                });
                setSuccess(true);
                setFormError(false);
                setTimeout(() => {
                    setSuccess(false);
                }, 1500)
            })
            .catch(() => {
                setFormError(true);
            });
    };


    const handleDeleteAccount = () => {
        return deleteUserRequest(tenantUserStore.getTenantId(), "not specified").then(() => {
            setSuccess(true);
            setDeleteMode(false);
            //TODO - logout user 
            navigate("/logout")
        })
            .catch(() => {
                setFormError(true);
            });
    }


    return (
        <Fragment>
            {!user && (
                <Box p={5}>
                    <Loading />
                </Box>
            )}
            {user && (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {t('settings.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={3}>
                                {tenantUserStore.staff &&
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography
                                                variant="overline">{t('settings.accessCode')}</Typography>
                                            <br />
                                            <Typography variant="h5">
                                                {user.accessCode}
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}>
                                            <Button variant="outlined" color="primary" size="small"
                                                onClick={refreshAccessCode}>{t('settings.refreshAccessCode')}</Button>
                                        </Grid>
                                    </Grid>
                                }
                                <Box my={2} />
                                <Typography variant="overline">{t('settings.changePassword')}</Typography>
                                <Box my={1} />
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <TextField value={passwordForm.password} name="password"
                                            size="small" variant="outlined" type="password"
                                            label={t('settings.password')}
                                            onChange={onPasswordFormChange} />
                                    </Grid>
                                    <Grid item>
                                        <TextField value={passwordForm.confirmPassword} name="confirmPassword"
                                            size="small" variant="outlined" type="password"
                                            label={t('settings.confirmPassword')}
                                            onChange={onPasswordFormChange} />
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary"
                                            disabled={!passwordForm.password || !passwordForm.confirmPassword}
                                            onClick={handleChangePassword}>{t('settings.changePassword')}</Button>
                                    </Grid>
                                </Grid>

                                {formError && (
                                    <Box mt={1}>
                                        <Alert severity="error">{t('settings.invalidPassword')}</Alert>
                                    </Box>
                                )}
                                {success && (
                                    <Box mt={1}>
                                        <Alert severity="success">{t('settings.passwordChanged')}</Alert>
                                    </Box>
                                )}

                                <Box my={2} />
                                <Typography variant="overline">{t('settings.delete.button')}</Typography>
                                <Box my={1} />
                                <Grid container spacing={3}>
                                    <Grid item> {t('settings.delete.description')}
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained"
                                            onClick={() => setDeleteMode(true)}>{t('settings.delete.button')}</Button>
                                    </Grid>
                                </Grid>

                                {deleteMode && <ConfirmDialog 
                                    title={t('users.delete.title')}
                                    message={t('users.delete.prompt')}
                                    reject={() => setDeleteMode(false)}
                                    resolve={() => handleDeleteAccount()} />}

                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {error && (
                <ErrorDialog error={error} onClose={() => setError(undefined)} />
            )}
        </Fragment>
    )
}
