import {ImportRequest, ImportResult, Item, Model, ModelDetails} from "./types";
import {Api} from "../Api";
import {Page, Paging} from "@variocube/app-ui";
import {makeCsvImportFormData} from "./form";

export function createModel(tenantId: string, details: ModelDetails) {
    return Api.POST<Model>(`tenants/${tenantId}/models`, details);
}

export function listModels(tenantId: string, paging?: Paging, needle?: string) {
    return Api.GET<Page<Model>>(`tenants/${tenantId}/models${paging ? paging.toQueryString() : '?unpaged=1'}${needle ? '&needle=' + needle : ''}`, { 'Accept': 'application/vnd.models.paged+json' });
}

export function getModel(tenantId: string, uuid: string) {
    return Api.GET<Model>(`tenants/${tenantId}/models/${uuid}`);
}

export function listModelItems(tenantId: string, modelUuid: string) {
    return Api.GET<Item[]>(`tenants/${tenantId}/models/${modelUuid}/items`);
}

export function updateModel(tenantId: string, uuid: string, details: ModelDetails) {
    return Api.PUT<Model>(`tenants/${tenantId}/models/${uuid}`, details);
}

export function deleteModel(tenantId: string, uuid: string) {
    return Api.DELETE<void>(`tenants/${tenantId}/models/${uuid}`);
}

export async function importModels(tenantId: string, importRequest: ImportRequest) {
    return Api.POST_FORM_DATA<ImportResult>(`tenants/${tenantId}/models/import`, makeCsvImportFormData(importRequest));
}