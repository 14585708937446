import {Alert, Box} from "@mui/material";
import {ErrorBoundary, VCThemeProvider, VCThemeProviderProps} from "@variocube/app-ui";
import {createElement, Fragment, useCallback, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {
	AuthCallbackProcessingPath,
	AuthCallbackRoute,
	AuthLogin,
	AuthLoginPath,
	AuthLogout,
	AuthLogoutPath,
	authStorage,
} from "./Auth";
import {User} from "./data/types";
import {StorageLocalizationProvider, useLocalization} from "./i18n";
import {CategoryCreate, CategoryImport, CategoryList, CategoryView} from "./pages/categories";
import {CostCenterCreate, CostCenterList, CostCenterView} from "./pages/costcenters";
import {BoxDetails, CubeDetails, CubeList} from "./pages/cubes";
import {IndexRedirection} from "./pages/IndexRedirection";
import {ItemCreate, ItemImport, ItemList, ItemView} from "./pages/items";
import {ModelCreate, ModelImport, ModelList, ModelView} from "./pages/models";
import {
	OverdueRentalList,
	RentalCreateByBorrowerWizard,
	RentalCreateByUserWizard,
	RentalList,
	RentalRequestList,
	RentalRequestView,
	RentalView,
} from "./pages/rentals";
import {Settings} from "./pages/settings";
import {SignupWizard} from "./pages/signup";
import {StatisticView} from "./pages/statistic";
import {TenantSelector} from "./pages/tenant";
import {TicketList, TicketView} from "./pages/tickets";
import {UserImport, UserList, UserSignUpSettings, UserView} from "./pages/users";
import {RentalAppLayout} from "./RentalAppLayout";
import {tenantUserStore} from "./store/TenantUserStore";

const PUBLIC_PATHS = [
	"/",
	"/signup",
	AuthLoginPath,
	AuthCallbackProcessingPath,
	AuthLogoutPath,
];

export function App() {
	const {location} = window;
	const [user, setUser] = useState<User>();

	const hasUser = (user !== undefined);
	const requireApproval = (user && !user.active);
	const [branding, setBranding] = useState<VCThemeProviderProps["branding"]>();

	const load = useCallback(async () => {
		try {
			const branding = await tenantUserStore.getBranding();
			setBranding(branding);
			const user = await tenantUserStore.load();
			setUser(user);
			console.debug("user loaded", user.username);
		} catch (error) {
			console.error("Tenant not set. Show tenant selector view.");
			if (location.pathname.indexOf("tenants") === -1) {
				location.href = "/tenants";
			}
		}
	}, []);

	useEffect(() => {
		// Redirect to login page if not authorized
		if (!authStorage.authenticated && PUBLIC_PATHS.indexOf(location.pathname) === -1) {
			setTimeout(() => {
				location.href = AuthLoginPath;
			}, 500);
			return;
		}
		// Load user if authorized
		if (authStorage.authenticated) {
			load()
				.then();
		}
	}, []);

	const handleTenantSelected = () => location.href = "/rentals";

	function getUser() {
		return {
			id: user?.username,
			name: user?.username,
		};
	}

	return (
		<VCThemeProvider branding={branding}>
			<StorageLocalizationProvider>
				<ErrorBoundary
					bugsnagConfig={{
						apiKey: "bf90d39c016784caf93d4e16972ceb5a",
						getUser,
					}}
					anonymizeStorageKeyPatterns={["auth", "credentials"]}
				>
					<BrowserRouter>
						<RentalAppLayout>
							<Routes>
								{requireApproval && <ApprovalPrompt />}
								<Route path="/" element={<IndexRedirection />} />

								<Route path={AuthLoginPath} element={<AuthLogin />} />
								<Route path={AuthLogoutPath} element={<AuthLogout />} />
								<Route path={AuthCallbackProcessingPath} element={<AuthCallbackRoute />} />

								<Route
									path="/tenants"
									element={<TenantSelector onTenantSelected={handleTenantSelected} />}
								/>

								{!hasUser && <Route path="/signup" element={<SignupWizard />} />}

								<Route path="/rentals" element={<RentalList />} />
								{tenantUserStore.staff && <Route path="/rentals/overdue" element={<OverdueRentalList/>} />}
								<Route
									path="/rentals/create/:uuid"
									element={tenantUserStore.staff
										? <RentalCreateByUserWizard />
										: <RentalCreateByBorrowerWizard />}
								/>
								<Route
									path="/rentals/create"
									element={tenantUserStore.staff
										? <RentalCreateByUserWizard />
										: <RentalCreateByBorrowerWizard />}
								/>
								<Route path="/rentals/:uuid" element={<RentalView />} />

								<Route path="/requests" element={<RentalRequestList />} />
								<Route path="/requests/:uuid" element={<RentalRequestView />} />

								<Route path="/models" element={<ModelList />} />
								{tenantUserStore.staff && <Route path="/models/create" element={<ModelCreate />} />}
								{tenantUserStore.staff && <Route path="/models/import" element={<ModelImport />} />}
								<Route path="/models/:uuid" element={<ModelView />} />

								<Route path="/items" element={<ItemList />} />
								{tenantUserStore.staff && <Route path="/items/create" element={<ItemCreate />} />}
								{tenantUserStore.staff && <Route path="/items/import" element={<ItemImport />} />}
								<Route path="/items/:uuid" element={<ItemView />} />

								<Route path="/tickets" element={<TicketList />} />
								<Route path="/tickets/:uuid" element={<TicketView />} />

								<Route path="/settings" element={<Settings />} />

								{tenantUserStore.admin && <Route path="/statistics" element={<StatisticView />} />}

								{tenantUserStore.staff && (
									<Fragment>
										<Route path="/cubes" element={<CubeList />} />
										<Route path="/cubes/:cubeId" element={<CubeDetails />} />
										<Route path="/cubes/:cubeId/boxes/:boxNumber" element={<BoxDetails />} />
									</Fragment>
								)}

								{tenantUserStore.staff && (
									<Fragment>
										<Route path="/cost-centers" element={<CostCenterList />} />
										<Route path="/cost-centers/create" element={<CostCenterCreate />} />
										<Route path="/cost-centers/:uuid" element={<CostCenterView />} />
									</Fragment>
								)}

								{tenantUserStore.staff && (
									<Fragment>
										<Route path="/categories" element={<CategoryList />} />
										{tenantUserStore.admin && (
											<Route path="/categories/create" element={<CategoryCreate />} />
										)}
										{tenantUserStore.admin && (
											<Route path="/categories/import" element={<CategoryImport />} />
										)}
										<Route path="/categories/:uuid" element={<CategoryView />} />
									</Fragment>
								)}

								{tenantUserStore.admin && (
									<Fragment>
										<Route path="/users" element={<UserList />} />
										<Route path="/users/import" element={<UserImport />} />
										<Route path="/users/signup-settings" element={<UserSignUpSettings />} />
										<Route path="/users/:uuid" element={<UserView />} />
									</Fragment>
								)}
							</Routes>
						</RentalAppLayout>
					</BrowserRouter>
				</ErrorBoundary>
			</StorageLocalizationProvider>
		</VCThemeProvider>
	);
}

function ApprovalPrompt() {
	const {t} = useLocalization();
	return (
		<Box mb={3}>
			<Alert severity="warning">{t("users.requiredApproval")}</Alert>
		</Box>
	);
}
